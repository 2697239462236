import { React } from "react";
// import { Link, NavLink } from "react-router-dom";
import { Link } from "react-scroll";

import "./Navbar.css";
import logo from "../assets/logos/greenup_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navbar">
      <img src={logo} alt="logo" className="logo" />
      {/* <FontAwesomeIcon
        icon={faBars}
        className="menu"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      /> */}
      {/* <div className={isNavExpanded ? "nav-links expanded" : "nav-links"}>
        <ul>
          <li>
            <NavLink to={"/"}>Únete</NavLink>
            <Link to="forms" smooth={true} offset={-70}>
              Únete
            </Link>
          </li>
          <li>
            <a href="#">Negocios</a>
          </li>
          <li>
            <a href="#">Ciudadanos</a>
          </li>
          <li>
            <Link to="people" smooth={true}>
              Sobre nosotras
            </Link>
          </li>
        </ul>
      </div> */}
    </nav>
  );
};

export default Navbar;
