import ciudadanos from "../assets/images/ciudadanos.png";
import negocios from "../assets/images/negocios.png";
import "./TwoClients.css";

function TwoClients() {
  return (
    <section className="clients">
      <div className="back">
        {/* <svg width="0" height="0">
          <filter id="grainy" x="0" y="0" width="100%" height="100%">
            <feTurbulence type="fractalNoise" baseFrequency=".537" />
            <feColorMatrix type="saturate" values="0" />
            <feBlend in="SourceGraphic" mode="multiply" />
          </filter>
        </svg> */}
      </div>
      <div className="clients-content">
        {/* <div className="title">Cómo lo hacemos</div>
        <p>Hay dos factores claves en nuestra comunidad</p> */}
        <div className="row">
          <div className="column">
            <div className="single-client">
              {/* <img src={ciudadanos} alt="planta"></img> */}
              <div className="tlt">Padrinos</div>
              <div className="desc">
                <p>
                  Con nuestra suscripción mensual podrás apadrinar el jardín de
                  tu negocio GreenUp más cercano, disfrutar de descuentos en el
                  local e interactuar con otros padrinos de la zona.
                </p>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="single-client">
              {/* <img src={negocios} alt="planta"></img> */}
              <div className="tlt">Negocios</div>
              <div className="desc">
                <p>
                  Instala un jardín vertical en la fachada de tu negocio. Crea
                  un espacio verde y sostenible e impulsa planes de fidelización
                  para tu comunidad de padrinos a través de la app.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TwoClients;
