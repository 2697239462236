import "./Press.css";
import uc3m from "../assets/logos/logo-uc3m.png";
import vanguardia from "../assets/logos/logo-vanguardia.png";
import myscience from "../assets/logos/logo-myscience.png";
import apte from "../assets/logos/logo-apte.png";

function Press() {
  return (
    <section className="press">
      <div className="title">Hablan de nosotras</div>
      <div className="row">
        <div className="column">
          <div className="single-logo pr">
            <a
              href="https://www.lavanguardia.com/vida/20220524/8288846/proyecto-crear-jardines-verticales-fachadas-gana-explorer-uc3m.html"
              target="_blank"
            >
              <img src={vanguardia} alt="la vanguardia logo"></img>
            </a>
          </div>
        </div>
        <div className="column">
          <div className="single-logo pr">
            <a
              href="https://www.uc3m.es/ss/Satellite/UC3MInstitucional/es/Detalle/Comunicacion_C/1371336357253/1371215537949/Un_proyecto_para_implantar_jardines_verticales_en_fachadas_de_edificios,_ganador_del_Explorer_UC3M"
              target="_blank"
            >
              <img src={uc3m} alt="uc3m logo" className="uc3m"></img>
            </a>
          </div>
        </div>
        <div className="column">
          <div className="single-logo pr">
            <a
              href="https://www.myscience.org/news/wire/a_project_to_set_up_vertical_gardens_on_building_facades_wins_the_explorer_uc3m_programme-2022-uc3m"
              target="_blank"
            >
              <img src={myscience} alt="myscience logo"></img>
            </a>
          </div>
        </div>
        <div className="column">
          <div className="single-logo pr">
            <a
              href="https://www.apte.org/proyecto-implantar-jardines-verticales-fachadas-edificios-ganador-explorer-uc3m"
              target="_blank"
            >
              <img src={apte} alt="apte logo"></img>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Press;
