import Marquee from "react-fast-marquee";
import "./marquee.css";
function MarqueeComponent() {
  return (
    <>
      <Marquee speed={150} className="marquee">
        <div className="quote">STOP FAKE PLANTS </div>
        <div className="quote line">MAKE IT GREEN</div>
        <div className="quote">MAKE MADRID GREEN AGAIN</div>
        <div className="quote line">CAMBIA TU BARRIO</div>
      </Marquee>
      <Marquee speed={150} direction="right">
        <div className="quote line">MAKE MADRID GREEN AGAIN</div>
        <div className="quote">MAKE IT GREEN</div>
        <div className="quote line">STOP FAKE PLANTS </div>
        <div className="quote">CAMBIA TU BARRIO</div>
      </Marquee>
    </>
  );
}
export default MarqueeComponent;
