import "./SDG.css";
import sdg03 from "../assets/logos/E_GIF_03.gif";
import sdg11 from "../assets/logos/E_GIF_11.gif";
import sdg13 from "../assets/logos/E_GIF_13.gif";

function SDG() {
  return (
    <section className="sdg">
      <div className="title">Comprometidas con los ODS</div>
      <div className="row">
        <div className="column">
          <div className="single-logo">
            <img src={sdg03} alt="planta"></img>
          </div>
        </div>
        <div className="column">
          <div className="single-logo">
            <img src={sdg11} alt="planta"></img>
          </div>
        </div>
        <div className="column">
          <div className="single-logo">
            <img src={sdg13} alt="planta"></img>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SDG;
