import "./App.css";
import HomeBlob from "./components/HomeBlob";
import Navbar from "./components/Navbar";
import ThreeSteps from "./components/ThreeSteps";
import Trust from "./components/Trust";
import Forms from "./components/Forms";
import Press from "./components/Press";
import AboutUs from "./components/AboutUs";
import TwoClients from "./components/TwoClients";
import AppScreens from "./components/AppScreens";
import SDG from "./components/SDG";
import Footer from "./components/Footer";
import ScrollToTop from "react-scroll-to-top";
import MarqueeComponent from "./components/marquee";
function App() {
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop smooth={true} style={{ right: "30px" }} />
      <HomeBlob />
      <ThreeSteps />
      <MarqueeComponent />
      <TwoClients />
      {/* <AppScreens /> */}
      <Trust />
      <SDG />
      {/* <Forms /> */}
      <Press />
      <AboutUs />
      <Footer />
    </div>
  );

  // return (
  //   <Router>
  //     <Navbar />
  //     <Routes>
  //       <Route index element={<Home />} />
  //       <Route path="/Forms" exact element={<Forms />} />
  //       <Route path="/AboutUs" element={<AboutUs />} />
  //     </Routes>
  //   </Router>
  // );
}

export default App;
