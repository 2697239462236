import picAmalia from "../assets/images/circ-Amalia.png";
import picAngela from "../assets/images/circ-Angela.png";
import picMarina from "../assets/images/circ-Marina.png";
import picJimena from "../assets/images/circ-Jimena.png";
import "./AboutUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function AboutUs() {
  return (
    <section className="people">
      <div className="people-title">El equipo</div>
      <div className="row">
        <div className="column">
          <div className="single-person">
            <img src={picAmalia} alt="planta" />
            <h3 className="job">CEO</h3>
            <h3>Amalia Cid</h3>
            <p>
              Ingeniera informática.
              <br />
              Emprendedora y multidisciplinar.
              <br />
              La apasionada del equipo.
            </p>
            <a
              href="https://es.linkedin.com/in/amalia-cid-blasco"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
        <div className="column">
          <div className="single-person">
            <img src={picAngela} alt="planta"></img>
            <h3 className="job">CPO</h3>
            <h3>Ángela Sueiro</h3>
            <p>
              Arquitecta.
              <br />
              Experta en jardines verticales.
              <br />
              El alma libre del equipo.
            </p>
            <a
              href="https://es.linkedin.com/company/green-up-cities"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
        <div className="column">
          <div className="single-person">
            <img src={picMarina} alt="planta"></img>
            <h3 className="job">CTO</h3>
            <h3>Marina Torelli</h3>
            <p>
              Ingeniera informática.
              <br />
              La creatividad está en su ADN.
              <br />
              La techie y diseñadora del equipo.
            </p>
            <a href="https://es.linkedin.com/in/marina-torelli" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
        <div className="column">
          <div className="single-person">
            <img src={picJimena} alt="planta"></img>
            <h3 className="job">CMO</h3>
            <h3>Jimena Bescansa</h3>
            <p>
              Filóloga, actriz y directora de cine.
              <br />
              Comunicadora nata y polivalente.
              <br />
              La moderna del equipo.
            </p>
            <a
              href="https://www.linkedin.com/in/jimena-donoso-bescansa-356b12214"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutUs;
