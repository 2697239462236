import comunidad_3steps from "../assets/images/comunidad_verde.png";
import jv3steps from "../assets/images/jardines_verticales_verde.png";
import ciudad_bosque_3s from "../assets/images/ciudad_bosque_verde.png";
import jvAxo from "../assets/images/axo-jardin-1m2.png";
import mobileApp from "../assets/images/mobileApp.svg";
import handshake from "../assets/images/handshake.svg";

import "./ThreeSteps.css";

function ThreeSteps() {
  return (
    <section className="steps">
      <div className="title">Qué hacemos</div>
      {/* <div className="descr">
        GreenUp Cities une a negocios y ciudadanos para devolver la naturaleza a
        las ciudades
      </div> */}
      <div className="row">
        <div className="column">
          <div className="single-step">
            <div className="image">
              <img src={jvAxo} alt="planta"></img>
            </div>
            <div className="title">Jardines verticales</div>
            <div className="desc">
              <p>
                En GreenUp colocamos jardines verticales modulares en las
                fachadas de los negocios de tu ciudad
              </p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="single-step">
            <div className="image">
              <img src={mobileApp} alt="planta" className="mobile"></img>
            </div>
            <div className="title">Solución digital</div>
            <div className="desc">
              {" "}
              <p>
                Con nuestra app podrás apadrinar los jardines e interactuar con
                negocios y ciudadanos
              </p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="single-step">
            {" "}
            <div className="image">
              <img src={handshake} alt="planta" className="handshake"></img>
            </div>
            <div className="title">Comunidad GreenUp</div>
            <div className="desc">
              {" "}
              <p>
                Entra a formar parte de la Comunidad GreenUp y conecta con
                ciudadanos y negocios mientras compensas tu huella de carbono
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ThreeSteps;
