import "./Trust.css";
import uc3m from "../assets/logos/logo-uc3m.png";
import upm from "../assets/logos/logo-upm.png";
import explorer from "../assets/logos/logo-explorer.png";
import acmw from "../assets/logos/logo-acm.png";
import ja from "../assets/logos/logo-ja.png";

function Trust() {
  return (
    <section className="trust">
      <div className="title">Ya han confiado en nosotras</div>
      <div className="row">
        <div className="column">
          <div className="single-logo">
            <img src={explorer} alt="planta"></img>
          </div>
        </div>
        <div className="column">
          <div className="single-logo">
            <img src={uc3m} alt="planta" className="uc3m"></img>
          </div>
        </div>
        <div className="column">
          <div className="single-logo">
            <img src={upm} alt="planta"></img>
          </div>
        </div>
        <div className="column">
          <div className="single-logo">
            <img src={acmw} alt="planta"></img>
          </div>
        </div>
        <div className="column">
          <div className="single-logo">
            <img src={ja} alt="planta"></img>
          </div>
        </div>
      </div>
      <div className="row-2">
        <div className="column">
          {/* <div id="container">
            <div id="circle">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="300px"
                height="300px"
                viewBox="0 0 300 300"
                enable-background="new 0 0 300 300"
                // xml:space="preserve"
              >
                <defs>
                  <path
                    id="circlePath"
                    d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                  />
                </defs>
                <circle cx="150" cy="100" r="75" fill="none" />
                <g>
                  <use href="#circlePath" fill="none" />
                  <text fill="#000">
                    <textPath href="#circlePath">
                      Winners of Explorer UC3M 2022 by SantanderX
                    </textPath>
                  </text>
                </g>
              </svg>
            </div>
          </div> */}
          <div className="awards">
            <div className="emoji">🏆</div>
            <div className="txt">Explorer UC3M</div>
            <div className="year">2022</div>
          </div>
        </div>
        <div className="column">
          {/* <div id="container">
            <div id="circle">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="300px"
                height="300px"
                viewBox="0 0 300 300"
                enable-background="new 0 0 300 300"
                // xml:space="preserve"
              >
                <defs>
                  <path
                    id="circlePath"
                    d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                  />
                </defs>
                <circle cx="150" cy="100" r="75" fill="none" />
                <g>
                  <use href="#circlePath" fill="none" />
                  <text fill="#000">
                    <textPath href="#circlePath">
                      Winners of Junior Achievement UC3M 2023
                    </textPath>
                  </text>
                </g>
              </svg>
            </div>
          </div> */}
          <div className="awards">
            <div className="emoji">🏆</div>
            <div className="txt">Junior Achievement UC3M</div>
            <div className="year">2023</div>
          </div>
        </div>{" "}
        <div className="column">
          <div className="awards">
            <div className="emoji">🏆</div>
            <div className="name">Premio Impacto ESG </div>
            <div className="company">PwC </div>
            <div className="txt">Junior Achievement Spain</div>
            <div className="year">2023</div>
          </div>
        </div>{" "}
        <div className="column">
          <div className="awards">
            <div className="emoji">🏆</div>
            <div className="name">Premio Sostenibilidad Energética</div>
            <div className="company">Naturgy</div>
            <div className="txt">Junior Achievement Spain</div>
            <div className="year">2023</div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Trust;
