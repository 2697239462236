import planta from "../assets/images/plant.jpeg";
import "./Forms.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Forms() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        form.current,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="forms" id="forms">
      <div className="title">Únete a GreenUp</div>
      {/* <p>
        Déjanos tu email para que podamos ponernos en contacto. Si eres un
        negocio te presentaremos nuestra propuesta de jardín vertical. Si eres
        ciudadano podrás elegir un jardín y apadrinarlo.
      </p> */}
      <p></p>
      <form ref={form} onSubmit={sendEmail} className="inputs">
        <div className="each">
          <input
            type="email"
            className="mail-input"
            placeholder="Correo electrónico"
          />
          <input type="submit" value="Soy ciudadano" />
        </div>
        <div className="each">
          <input
            type="email"
            className="mail-input"
            placeholder="Correo electrónico"
          />
          <input type="submit" value="Soy negocio" />
        </div>
      </form>
    </section>
  );
}
export default Forms;
