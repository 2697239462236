import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <section className="footer">
      <div className="love">Made with 💚 by GreenUp Cities © 2023</div>
      <div className="icons">
        <a href="https://es.linkedin.com/company/green-up-cities">
          <FontAwesomeIcon icon={faLinkedin} className="icon" />
        </a>
        <a href="https://www.instagram.com/greenupcities/">
          <FontAwesomeIcon icon={faInstagram} className="icon" />
        </a>
        <a href="https://twitter.com/GreenUpCities">
          <FontAwesomeIcon icon={faTwitter} className="icon" />
        </a>
        <a href="https://www.tiktok.com/@greenupcities">
          <FontAwesomeIcon icon={faTiktok} className="icon" />
        </a>
      </div>
    </section>
  );
}
export default Footer;
